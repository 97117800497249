<template>
  <div class="introContentProfile">
    <IntroBanner
      v-if="isOnlyMobile"
      :objBanner="objBannerIntro"
      :classImage="'img-354-164 img-wrapper'"
      :classLoading="'placeholders-banner-product'"
    />
    <section class="mt-2">
      <div class="d-flex align-items-center mb-4">
        <p class="titleProfileIntro mr-3">Xin chào, {{ getUserName }}</p>
        <a class="pointer" @click="handlerLogout">
          <img src="../../assets/img/icon/icon_logout.svg" width="23" height="23" alt="Tài Lộc" />
        </a>
      </div>
    </section>
    <section>
      <div class="bg-white py-5 px-4">
        <p class="text-center">
          Chào mừng bạn đã tham gia <span class="font-weight-600">Tài Lộc</span>, <br />
          Hy vọng các thông tin mua sắm chúng tôi<br />
          mang lại sẽ giúp ích cho bạn.
        </p>
      </div>
    </section>
  </div>
</template>
<script>
import IntroBanner from '@/components/Intro/Banner/IntroBanner';
export default {
  components: {
    IntroBanner
  },
  computed: {
    getUserName() {
      let user = this.$store.getters.getterUserInfo || { name: '', phone: '' };
      return user.name || user.phone;
    },
    objBannerIntro() {
      return {
        pos_01: [
          {
            image_url: 'https://d1aleksa3dshq6.cloudfront.net/muaho-concept/bannerIntro.jpg',
            target_url: '#',
            is_affiliate: 1,
            target_type: '_self'
          }
        ]
      };
    },
    isOnlyMobile() {
      return this.$store.getters.getterIsOnlyMobile;
    }
  },
  methods: {
    handlerLogout() {
      this.$store.dispatch('destroyToken').then(() => {
        if (this.$route.path !== '/mua-ho-concept') {
          this.$router.push('/mua-ho-concept');
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.introContentProfile {
  padding: 10px;
  margin-bottom: 60px;
  // min-height: calc(100vh - 110px);
}
</style>
